/*Font Size System
Major Third https://type-scale.com/
7.451rem
74.51px
5.96rem
59.60px
4.768rem
47.68px
3.815rem
38.15px
3.052rem
30.52px
2.441rem
24.41px
1.953rem
19.53px
1.563rem
15.63px
1.25rem
12.50px
1rem
10.00px
0.8rem
8.00px
0.64rem
6.40px
0.512rem
5.12px
0.41rem
4.10px
*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9rem;
  color: #111;
}

h1,
h2,
h3,
h4 {
  line-height: 1.3;
}

h1 {
  font-size: 4.2rem;
}

h2 {
  font-size: 3.2rem;
}

h3 {
  font-size: 2.2rem;
  font-weight: 600;
}

h4 {
  font-size: 1.953rem;
  font-weight: 600;
}

p {
  line-height: 1.5;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3rem;
}

a:link,
a:visited {
  text-decoration: none;
}

.btn {
  border: none;
  background: transparent;
  padding: 1.5rem 3rem;
  border-radius: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 1.6rem;
  text-transform: uppercase;
}

img {
  width: 100%;
  border-radius: 1rem;
  border: 1px solid #ddd;
  display: block;
}

/*Utility Classes*/

.btn-light {
  border: 2px solid #fff;
  color: #fff;
}

.btn-dark {
  border: 2px solid #111;
  color: #111;
}

.card {
  border-radius: 1rem;
}

.underline {
  border-bottom: 0.2rem solid;
}

/*Site Logo*/
.site-logo {
  color: #fff;
  font-weight: 700;
  border: none;
  border-radius: 0;
}
