.sponsors {
  background-color: #f2f4f7;
}

.sponsors .section-content {
  display: flex;
  flex-direction: column;
  gap: 6rem;
}

.sponsors .section-heading {
  text-align: center;
}

.sponsors .cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.sponsors .card {
  display: flex;
  border: 1px solid #ddd;
  padding: 1rem;
}

.sponsors .card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.sponsors .btn {
  align-self: center;
}

@media (max-width: 650px) {
  .sponsors .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 350px) {
  .sponsors .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
