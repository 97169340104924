.hero-text {
  font-size: 2.441rem;
  color: #fff;
}

@media (max-width: 700px) {
  .hero-text {
    font-size: 2.441rem;
  }
  .hero-status {
    font-size: 1.6rem;
  }
}
