.mapboxgl-map {
  border-radius: 1rem;
}

/*Popup Card*/

.mapboxgl-popup-content {
  border-radius: 1rem;
  opacity: 0.9;
}

.popupCard {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 22rem;
}

.popupCardMedia img {
  width: 100%;
  object-fit: cover;
}

.popupCardContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.popupCardTextBox {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.popupCardText {
  font-size: 1.4rem;
}

.popupCardTextBoxHeading {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #111;
  line-height: 1.5;
}

.popupCardIssues,
.popupCardCoordinates {
  list-style: none;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.issue,
.coordinate {
  font-size: 1.4rem;
}

.issue:not(:last-child)::after,
.coordinate:not(:last-child)::after {
  content: ',';
}

/*Marker Popup*/
.mapboxgl-popup-close-button {
  font-size: 2.8rem;
  padding: 0 1rem;
}

.mapboxgl-popup-close-button:hover {
  background: none;
}
