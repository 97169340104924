.main-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.main-nav .hamburger {
  display: none;
  height: 0;
}

.main-nav .site-logo {
  width: 5rem;
}

@media (max-width: 850px) {
  .main-nav .hamburger {
    display: flex;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 4rem;
  }

  .main-nav .main-nav-children {
    display: none;
  }

  .main-nav .main-nav-children.active {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
