.main-nav-menu {
  list-style: none;
  display: flex;
  gap: 3rem;
}

.main-nav-menu-item {
  display: flex;
}

.main-nav-menu-item-link {
  color: #fff;
  font-weight: 500;
  font-size: 1.9rem;
  cursor: pointer;
}

@media (max-width: 850px) {
  .main-nav-menu {
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
}
