.meet-the-team .section-heading {
  text-align: center;
}

.meet-the-team .cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 3rem;
  row-gap: 6rem;
}

.meet-the-team .card {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.meet-the-team .card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  text-align: center;
}

.meet-the-team .card-heading {
  text-align: center;
}

.meet-the-team .card-text {
  font-size: 1.9rem;
  opacity: 0.7;
}

.meet-the-team img {
  border-radius: 50%;
  border: 1px solid #ccc;
  padding: 1rem;
}

@media (max-width: 860px) {
  .meet-the-team .cards {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 650px) {
  .meet-the-team .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 350px) {
  .meet-the-team .cards {
    grid-template-columns: repeat(1, 1fr);
  }
}
