.get-involved .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
}

.get-involved .card {
  background-color: #f2f4f7;
  border-radius: 1.5rem;
  padding: 3rem;
}

.get-involved .section-heading {
  text-align: center;
}

.get-involved .section-text-box {
  gap: 6rem;
}

.get-involved .section-text {
  font-size: 2.441rem;
}

.get-involved .btn {
  align-self: center;
}

@media (max-width: 950px) {
  .get-involved .cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .get-involved .card {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 650px) {
  .get-involved .cards {
    grid-template-columns: 1fr;
    gap: 6rem;
  }
  .get-involved .card {
    grid-template-columns: 1fr;
  }
}
