.how-it-works .section-heading {
  text-align: center;
}

.how-it-works .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 6rem;
}

.how-it-works .card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 3rem;
  background-color: #f2f4f7;
  padding: 1.5rem;
  align-content: flex-start;
}

@media (max-width: 950px) {
  .how-it-works .cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .how-it-works .card {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 650px) {
  .how-it-works .cards {
    grid-template-columns: 1fr;
    gap: 6rem;
  }
  .how-it-works .card {
    grid-template-columns: 1fr;
  }
}
