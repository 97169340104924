/*Section Hero*/

.sectionHero {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('../../public/pedestrians-walking.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8rem;
  padding-bottom: 8rem;
  /* height: 100vh; */
}

/*Hero*/
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  text-align: center;
}

/*Hero Header*/

.heroHeading {
  font-weight: 800;
}
