.footer {
  background-color: #f2f4f7;
}

.footer li {
  list-style-type: none;
}

.footer .section-content {
  display: flex;
  justify-content: space-between;
}

.footer .section-text-box {
  gap: 1.5rem;
}

.footer .section-text-box a:link,
.footer .section-text-box a:visited {
  color: #000;
}

@media (max-width: 550px) {
  .footer .section-content {
    flex-direction: column;
    justify-content: flex-start;
    gap: 6rem;
    align-items: center;
  }
  .footer .section-text-box:nth-child(1) {
    order: 1;
  }
}
